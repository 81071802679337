import useGeneralStyles from "@components/Services/styles";
import React, { useEffect, useState } from "react";
import useStyles from "./PaymentStatus.styles";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { ButtonPrimary } from "@components/Reusables/ButtonPrimary";
import { useCurrentUser } from "@apollo";
import { ButtonOutlined } from "@components/Reusables";
interface creditsProps {
  image: string;
  titleText: string;
  descriptionText: string;
  backgroundColorCode: string;
  backRouteFunction: () => void;
  showMail: boolean;
  showForm: boolean;
}

const PaymentStatus: React.FC<creditsProps> = ({
  image,
  titleText,
  descriptionText,
  backgroundColorCode,
  backRouteFunction,
  showMail,
  showForm
}) => {
  const classes = useStyles({ ColorCode: backgroundColorCode });
  const { user: currentUser } = useCurrentUser();
  const [ShowMailText, setShowMailText] = useState(false);
  const [formDisplay, setFormDisplay] = useState(false);
  useEffect(() => {
    setShowMailText(showMail);
  });
  return (
    <>
      {!formDisplay ? (
        <div className={classes.container}>
          <div className={classes.left}>
            <img src={image} alt="" />
          </div>
          <div className={classes.right}>
            <p className={classes.congratsText}>{titleText}</p>
            <p className={classes.descriptionText}>{descriptionText}</p>
            {ShowMailText && (
              <div className={classes.mail}>
                <EmailOutlinedIcon htmlColor="#FFFFFF" />
                <p className={classes.mailText}>{currentUser?.email}</p>
              </div>
            )}
            {showForm && (
            <div className={classes.buttonContainer}>
              <ButtonOutlined
                className={classes.buttonOutlined}
                onClick={() => setFormDisplay(true)}
                text="Completar formulario"
              />
            </div>
            )}
            <div className={classes.buttonContainer}>
              <ButtonPrimary
                className={classes.button}
                onClick={backRouteFunction}
                text="Listo"
                noDegraded
              />
            </div>
          </div>
        </div>  
      ):(
        <div className={classes.container}>
          <iframe className={classes.formContainer} src="https://docs.google.com/forms/d/e/1FAIpQLScGAIwuhR_7H5rvR3KHkAqt4qw5zvhxvikw9zSIE1QisGptqA/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>    
        </div>
      )}
      
    </>
  );
};
export default PaymentStatus;
