import React, { useEffect, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import { Theme, makeStyles, useMediaQuery } from '@material-ui/core';
import { LayoutOnBoarding, SEO } from '@components';
import { Route } from '@interfaces';
import { LayoutMobileInvestments } from '@components/Investments/Layout';
import { useAuth } from '@hooks';
import SuccessfulPayment from '@components/Services/PaymentStatus/PaymentStatus';

import { SUCCESSFUL_PAY_ASTRONAUT } from 'images/GoogleCloud';
import { getUserSubscriptions, updateUserSubscription } from '@apollo';
import { useApolloClient } from '@apollo/client';

const SaveByRokinPage: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const client = useApolloClient();
  const isMobile = useMediaQuery('(max-width: 1270px)');
  const { userSubscriptions } = getUserSubscriptions();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [textToShow, setTextToShow] = useState<string>(
    'Has contratado tu plan. Te enviaremos las instrucciones para activarlo y comenzar a disfrutarlo a este correo:'
  );

  useEffect(() => {
    const planId = Number(window.location.href.split('#')[1]);

    const unpaidSubscription = userSubscriptions.find(
      (subs) => subs.planId.id === planId
    );

    const dataToSend = {
      id: unpaidSubscription?.id,
      paid: true,
    };

    updateUserSubscription(client, dataToSend);
  }, [userSubscriptions]);

  useEffect(() => {
    const location = window.location.href.split('#')[1];
    if (Number(location) === 1) {
      setShowForm(true);
      setTextToShow(
        'Has contratado tu plan. Para activarlo, completa el formulario que encontrarás a continuación y ¡comienza a disfrutar! Cualquier información adicional la enviaremos a este correo:'
      );
    } else {
      setShowForm(false);
      setTextToShow(
        'Has contratado tu plan. Te enviaremos las instrucciones para activarlo y comenzar a disfrutarlo a este correo:'
      );
    }
  }, []);

  return (
    <>
      {isMobile ? (
        <LayoutMobileInvestments activeServices>
          <>
            <SuccessfulPayment
              image={SUCCESSFUL_PAY_ASTRONAUT}
              titleText={'¡Felicidades!'}
              descriptionText={textToShow}
              backgroundColorCode={'#0A5669'}
              backRouteFunction={() => navigate(Route.services)}
              showMail={true}
              showForm={showForm}
            />
          </>
        </LayoutMobileInvestments>
      ) : (
        <>
          <SEO />
          <LayoutOnBoarding>
            <>
              <SuccessfulPayment
                image={SUCCESSFUL_PAY_ASTRONAUT}
                titleText={'¡Felicidades!'}
                descriptionText={textToShow}
                backgroundColorCode={'#0A5669'}
                backRouteFunction={() => navigate(Route.services)}
                showMail={true}
                showForm={showForm}
              />
            </>
          </LayoutOnBoarding>
        </>
      )}
    </>
  );
};

export default SaveByRokinPage;
